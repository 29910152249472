<!--
 * @Author: wyq
 * @Date: 2021-06-08 14:35:14
 * @LastEditTime: 2021-10-26 09:48:56
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\views\login.vue
-->
<template>
  <div class="page-login">
    <div class="login-box">
      <div class="header">
        <img src="../assets/logo.png" />
      </div>
      <form class="content">
        <v-text-field
          prepend-inner-icon="icon-user"
          label="账号"
          v-model="form.username"
          clearable
          :type="init?'text':'password'"
        ></v-text-field>
        <v-text-field
          prepend-inner-icon="icon-password"
          label="密码"
          v-model="form.password"
          :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
          :type="passwordShow ? 'text' : 'password'"
          @click:append="passwordShow = !passwordShow"
          @keydown.enter="login"
        ></v-text-field>
        <v-btn color="primary" fab large dark :loading="loading" @click="login">
          <v-icon>icon-enter</v-icon>
        </v-btn>
        <!-- <van-field v-model="form.username" placeholder="请输入学号" clearable />
        <van-field v-model="form.password" type="password" placeholder="请输入密码" clearable />
        <van-button type="primary" size="large" round :loading="loading" @click="login">登录</van-button>-->
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      passwordShow: false,
      loading: false,
      init: false,
    }
  },
  methods: {
    register() {
      let params = this.form
      params.name = '吴雨晴'
      params.class = '高一（1）班'
      params.studentNumber = 123
      this.$service.register(params).then(() => {
        console.log('注册成功！')
      })
    },
    login() {
      this.loading = true
      let params = this.form
      this.$service
        .login(params)
        .then((res) => {
          this.loading = false
          localStorage.setItem('token', res.accessToken)
          localStorage.setItem('userName', res.name)
          localStorage.setItem('userGrade', res.grade)
          localStorage.setItem('userClass', res.class)
          localStorage.setItem('userType', res.type) //账户类型 1管理员 2普通用户
          this.$router.push({
            name: 'AdaptiveTesting',
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
  mounted() {
    this.init = true
  },
}
</script>
<style lang="scss" scoped>
.page-login {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  &::before {
    position: absolute;
    top: -25vw;
    left: -25vw;
    display: block;
    width: 150vw;
    height: 50vw;
    border-radius: 50%;
    content: '';
    background: linear-gradient(89deg, #027eff 12%, #0148ff 93%);
  }

  .login-box {
    width: 486px;
    height: 506px;
    background: #ffffff;
    border-radius: 32px;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
    .header {
      padding: 48px 0;
      margin: 0 48px;
      text-align: center;
      border-bottom: 1px solid rgba(#eeeeee, 0.5);
      img {
        width: 332px;
      }
    }
    .content {
      padding: 48px;
      text-align: center;
      ::v-deep .v-input__control {
        .v-input__slot {
          padding-bottom: 8px;
          &::before {
            border-color: rgba(#eeeeee, 0.5);
          }
          .v-icon {
            color: #d8d8d8;
          }
          .v-input__icon--prepend-inner {
            border-right: 1px solid rgba(#eeeeee, 0.5);
            min-width: 40px;
            margin-right: 8px;
          }
        }
      }
      .v-btn {
        margin: 24px;
      }
    }
  }
}
</style>
